import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Paragraph1, Paragraph4 } from 'baseui/typography'
import { FiInstagram, FiYoutube } from 'react-icons/fi'
import Header from './header'
import walinkLogo from '../images/logo-walink.svg'
import Emoji from './Emoji'
import BottomBanner from './BottomBanner'

const Layout = ({ children, hero, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} page={page} />
      {hero && <> {hero}</>}

      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          minHeight: '80vh',
        }}
      >
        <main>{children}</main>
      </div>
      <footer
        style={{
          marginTop: 'auto',
          backgroundColor: '#f3f3f3',
          padding: 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            maxWidth: 960,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 20,
              alignItems: 'center',
            }}
          >
            <img src={walinkLogo} alt="logo-black" style={{ height: 50 }} />
            <div
              style={{
                display: 'flex',
                margin: '10px 0',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <a
                href="https://instagram.com/wa.link"
                target="_blank"
                alt="Walink Instagram"
                rel="noopener noreferrer"
                style={{ lineHeight: 1, marginTop: '2px' }}
              >
                <FiInstagram style={{ verticalAlign: 'middle' }} />
              </a>
              <span style={{ margin: '0 5px' }}> | </span>
              <a
                href="https://x.com/walink_inc"
                target="_blank"
                alt="Walink Twitter"
                rel="noopener noreferrer"
                style={{ lineHeight: 1, marginTop: '3px' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </a>
              <span style={{ margin: '0 5px' }}> | </span>
              <a
                href="https://www.youtube.com/channel/UCaZ90ujsRWeKWpAJoNwsVWg"
                target="_blank"
                alt="Walink YouTube"
                rel="noopener noreferrer"
                style={{ lineHeight: 1, marginTop: '2px' }}
              >
                <FiYoutube style={{ verticalAlign: 'middle' }} />
              </a>
            </div>
          </div>
          <div style={{ maxWidth: '830px' }}>
            <Paragraph1 style={{ color: '#343434', margin: 0 }}>
              Made with <Emoji symbol={'💚'} /> by Walink Inc.{' '}
              {new Date().getFullYear()}, All rights reserved
            </Paragraph1>

            <Paragraph4 style={{ color: '#343434', margin: 0 }}>
              Walink Inc. is neither associated with nor sponsored by WhatsApp
              Inc. or Meta Platforms Inc. We offer a service based on WhatsApp’s
              public API. By using our service, you are accepting our{' '}
              <a
                style={{ color: '#4299E1' }}
                href="https://walink.io/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>{' '}
              and{' '}
              <a
                style={{ color: '#4299E1' }}
                href="https://walink.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy.
              </a>{' '}
              | hi@wa.link
            </Paragraph4>
          </div>
        </div>
        {/* <div
          style={{
            position: 'fixed',
            display: 'flex',
            bottom: 0,
            minHeight: 150,
            width: '100%',
            backgroundColor: '#000',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paragraph1
            style={{
              color: '#fff',
              margin: '10px',
              textAlign: 'center',
            }}
          >
            We are currently experiencing problems with our generator of links
            and we are working to solve them as soon as possible. We appreciate
            your understanding
          </Paragraph1>
        </div> */}
        <BottomBanner />
      </footer>
    </>
  )
}

export default Layout
